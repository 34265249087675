import React, { ReactNode } from "react";
import {
  heading150,
  heading50,
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";

interface VideoWithTextProps {
  text: ReactNode;
  video: string;
}

const VideoWithText = ({ text, video }: VideoWithTextProps) => {
  return (
    <Container>
      <VideoWrapperDesktop>
        <Video
          src={video}
          width="100%"
          height="100%"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
        />
      </VideoWrapperDesktop>
      <TextWrapper>{text}</TextWrapper>
    </Container>
  );
};

export default VideoWithText;

const Container = styled("div", {
  maxWidth: 800,
  width: "90%",
  margin: "auto",
  paddingBottom: 0,
  paddingTop: 0,
  textAlign: "center",
  "@md": {
    display: "flex",
    width: "90%",
    maxWidth: 1100,
    paddingTop: 75,
    paddingBottom: 75,
    textAlign: "left",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #D9D9D9",
  },
});

const VideoWrapperDesktop = styled("div", {
  padding: "30% 0 0 0",
  position: "relative",
  flex: "1 0 50%",
  borderRadius: 4,

  display: "none",
  "@lg": {
    display: "block",
  },
});

const Video = styled("iframe", {
  position: "absolute",
  top: 0,
  left: 0,
  "@md": {
    borderRadius: 4,
    overflow: "hidden",
  },
});

const TextWrapper = styled("div", {
  ...heading50,
  padding: 16,
  paddingTop: 0,
  fontSize:14,
  "@md": {
    ...heading150,
    padding: 32,
  },
});
