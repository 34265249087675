import { graphql, Link, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/Layout";
import IconsSections from "../components/productPages/IconsSections";
import VideoWithText from "../components/productPages/VideoWithText";
import Hero from "../components/shared/Hero/Hero";
import unescape from "../utils/usescape";
import ClockIcon from "../components/productPages/assets/clock.inline.svg";
import SavingsIcon from "../components/productPages/assets/savings.inline.svg";
import VolumeIcon from "../components/productPages/assets/volume.inline.svg";
import GraphIcon from "../components/productPages/assets/graph.inline.svg";
import TextWithTitleAndButton from "../components/shared/TextWithTitleAndButton/TextWithTitleAndButton";
import ColumnWithImage from "../components/productPages/ColumnWithImage";
import { styled } from "../stitches/stitches.config";
import GettingBetterTimeline from "../components/shared/GettingBetterTimeline/GettingBetterTimeline";
import ActionableData from "../components/shared/actionableData";
import Testimonial from "../components/shared/Testominial/Testimonial";
import NextStep from "../components/shared/NextSteps/NextSteps";
import RedLineVImage from "../../src/images/red_line_v.png";
import RedLineHImage from "../../src/images/red_line_h.png";

const seo = {
  title: "PLX-Benchtop",
};

const hero = {
  title: "Supercharged mechanical testing",
  byline:
    "The PLX-Benchtop is a compact mechanical testing device that extracts metal stress-strain curves from indentation test data.",
};

const textWithVideo = {
  text:
    "Research, develop, and manufacture products faster than competitors that are still using traditional testing methods.",
  video:
    "https://player.vimeo.com/video/765993773?autoplay=1&loop=0&muted=1&title=0&byline=0&portrait=0",
};

const icons = [
  {
    icon: <ClockIcon />,
    title: "80X faster than tensile testing",
    description:
      "Our fully automated process generates stress-strain curves in under five minutes ",
  },
  {
    icon: <SavingsIcon />,
    title: "Over 90% reduction in costs ",
    description:
      "Massively reduce testing costs compared to conventional tensile testing.",
  },
  {
    icon: <VolumeIcon />,
    title: "99% lower volume material",
    description:
      "Test real components and small samples. No dogbones and  minimal preparation.",
  },
  {
    icon: <GraphIcon />,
    title: "Map properties across materials",
    description:
      "Map stress-strain properties across inhomogenous parts such as welds and forgings",
  },
];

const poweredByCorsica = {
  title: "Powered by CORSICA",
  description: [
    "The CORSICA software package enables the PLX-Benchtop to extract stress-strain curves from metallic materials in less than 5 minutes using an accelerated inverse finite element method. We call it PIP!",
    "PIP is fast, accurate, and proven. It is applicable to all of the most common engineering metals (and their alloys) and is already deployed by industry heavyweights and leading institutions globally.",
  ],
};

function Benchtop() {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "hero-background.png" }) {
        childImageSharp {
          gatsbyImageData(width: 800, placeholder: TRACED_SVG)
        }
      }
      benchtop: file(relativePath: { eq: "benchtop_render_v3_desktop.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, placeholder: TRACED_SVG)
        }
      }
      mobileImage: file(relativePath: { eq: "benchtop_render_v3_mobile.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, placeholder: TRACED_SVG)
        }
      }
      corsica: file(relativePath: { eq: "corsica.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1100, placeholder: TRACED_SVG)
        }
      }
      corsica2: file(relativePath: { eq: "corsica2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1100, placeholder: TRACED_SVG)
        }
      }
      testimonialImage: file(relativePath: { eq: "testimonials/testimonial_a.png" }) {
        childImageSharp {
          gatsbyImageData(width: 134, placeholder: BLURRED)
        }
      }
    }
  `);

  const bg = undefined;
  const image = getImage(data.benchtop);
  const mobileImage = getImage(data.mobileImage);
  const light = undefined;
  const testimonialImage = getImage(data.testimonialImage);


  return (
    <Layout seo={seo} backgroundColor="white" backgroundColorUnderneath="white">
      <Hero
        title={unescape(hero.title)}
        bylineMaxWidth={412}
        secondaryButton={{
          text: "Request a quote",
          path: "/request-a-quote",
        }}
        byline={hero.byline}
        image={image}
        mobileImage={mobileImage}
        bg={bg}
        light={light}
        page="benchtop"
      />
      <VideoWithText {...textWithVideo} />
      <IconsSections icons={icons} />
      <MobileContainer>
      <RedLineV/>
      </MobileContainer>
      <RedLineH>
      <StyledColumnWithImage
        image={getImage(data.corsica)}
        imageAlt="PLX-Benchtop with CORSICA software"
      >
        <DesktopContainer>
          <RedLineV/>
          <StyledTextWithTitleAndButton
            title={poweredByCorsica.title}
            button={{
              text: "Learn more about PIP",
              path: "/blogs/a-brief-overview-of-pip",
              tier: "bordered",
              padding: true


            }}
          >
            {poweredByCorsica.description}
          </StyledTextWithTitleAndButton>
          <RedLineV/>
        </DesktopContainer>
      </StyledColumnWithImage>
      </RedLineH>
      <MobileContainer>
        <StyledTextWithTitleAndButton
          title={poweredByCorsica.title}
          button={{
            text: "Learn more about PIP",
            path: "/blogs/a-brief-overview-of-pip",
            tier: "bordered",
            padding: true
          }}
        >
          {poweredByCorsica.description}
        </StyledTextWithTitleAndButton>
        <RedLineV/>
      </MobileContainer>
        
          <GettingBetterTimeline />
      
      <GradientWrapper>
        <ActionableData />
        <DesktopContainer>
          <Testimonial
            image={testimonialImage}
            name={"Dr Danielle Cote"}
            position={
              "Assistant Professor in Materials Science & Engineering | Worcester Polytechnic Institute"
            }
            description={
              "“PIP has enabled same-day testing, analysis of processed specimens, and remarkable cost savings”"
            }
          />
        </DesktopContainer>
        <NextStep />
      </GradientWrapper>
    </Layout>
  );
}

const StyledTextWithTitleAndButton = styled(TextWithTitleAndButton, {
  //display: "none",
  paddingLeft: 20,
  paddingBottom: 0,
  minWidth: "initial",
  backgroundColor:"#f9f9fb",
  "@md": {
    // display: "block",
    paddingTop: 80,
    paddingBottom: 0,
    paddingLeft: 120,
    minWidth: "590px",
  },
  p: {
    "@md": { maxWidth: 380 },
    //transform: "scale(0.9)",
  },
  button: {
    marginTop:"5px",
    scale:0.9,
    "@md": { display:"flex", scale:1,},
    div: {paddingTop:0}
    
  },

});

const StyledColumnWithImage = styled(ColumnWithImage, {
  "& div": { paddingTop: "0px",paddingBottom: "0px", },
  "& .gatsby-image-wrapper": {
    //transform: "translateX(-12%)",
    marginTop:"0px",
    paddingBottom: "0px",
    //backgroundImage: `url(${RedLineHImage})`,
  backgroundPosition: "0px 66%",
  backgroundRepeat: "repeat-x",
  backgroundColor:"#f9f9fb",
  img: {width:"84%",margin:"0 auto"},
"@md":{
  marginTop:"200px",
  paddingBottom: "0px",
  backgroundImage:"none",
},
  },
  "h4": {
    marginTop: "0px",
  }
});

const GradientWrapper = styled("div", {
  width: "100%",
  margin: "auto",
  maxWidth: 1140,
  background: "linear-gradient(180deg, #F5FAFF 25%, rgba(245, 250, 255, 0) 100%)",
  "@md": {
    borderRadius: 16,
    width: "90%",
    margin: "0px auto",
  },
});

const DesktopContainer = styled("div", {
  display: "none",
  "@md": {
    display: "block",
  },
})

const MobileContainer = styled("div", {
  display: "block",
  "@md": {
    display: "none",
  },
})

const RedLineV = styled("div", {
  height:"67px",
  //backgroundImage: `url(${RedLineVImage})`,
  backgroundPosition: "31% top",
  backgroundRepeat: "no-repeat",
  backgroundColor:"#f9f9fb",
  "@md": {
    height:"160px",
    //backgroundImage: `url(${RedLineVImage})`,
    backgroundPosition: "125px top",
    backgroundRepeat: "no-repeat",
  },
})

const RedLineH = styled("div", {
  
  
  
})

export default Benchtop;
