import React, { useState } from "react";
import { Link } from "gatsby";

import {
    paragraphTextSmall,
    heading150,
    heading200,
    heading300,
} from "../../../stitches/combinedStyles";
import { styled } from "../../../stitches/stitches.config";
import Button from "../Button/Button";
import Pin from "./pin.inline.svg";

const title = `Getting better\nall the time`;
const description = `Our testing technologies are continuously improved through software updates and new module releases. Take a look at our proposed product release timeline.`

const GettingBetterTimeline = () => {
    const blocks = [
        {
            icon: <Pin />,
            title: "Surface Profilometry",
            description:
                "Independent profilometery measurements as part of CORISCA 4.0",
            secondaryButton: {
                text: null,
                path: null,
            },
            coming: "Coming 2023 Q2",
        },
        {
            icon: <Pin />,
            title: "PLXUS",
            description:
                "Results archive, test insights, and data analytics",
            secondaryButton: {
                text: null,
                path: null,
            },
            coming: "Coming 2023",
        },
        {
            icon: <Pin />,
            title: "PLX-HotStage",
            description:
                "Mechanical properties at\ntemperatures of up to 800°C",
            secondaryButton: {
                text: "Learn more",
                path: "/hotpip",
            },
            coming: null
        },
        {
            icon: <Pin />,
            title: "Automated Mapping",
            description:
                "An automated moving stage to create indent maps",
            secondaryButton: {
                text: null,
                path: null,
            },
            coming: "Coming 2024",
        }
    ];

    const Block = ({
        icon,
        title,
        description,
        linkPath,
        text,
        coming
    }: {
        icon: any;
        title: string;
        description: string;
        linkPath?: string | null;
        text?: string | null
        coming?: string | null
    }) => (
        <BlockWrapper>
            {icon}
            <BlockTitle>{title}</BlockTitle>
            <BlockDescription className={title}>{description}</BlockDescription>
            <BlockLast>
                {(linkPath && <Link to={linkPath}>
                    <Button size="large" tier="bordered" arrow
                        style={{ marginTop: '-4px' }}>
                        {text}
                    </Button>
                </Link>)}
                {(coming && <BlockComing>{coming}</BlockComing>)}
            </BlockLast>
        </BlockWrapper>
    );

    return (
        <Container>
            <ContentContainer>
                <TextContent>
                    <Title>{title}</Title>
                    <DesktopContainer><Description>{description}</Description></DesktopContainer>
                </TextContent>
                <OverflowDiv>
                    <ScrollContainer>
                        <BlockContent>
                            {blocks.map((block, i) => (
                                <Block
                                    icon={block.icon}
                                    title={block.title}
                                    description={block.description}
                                    text={block.secondaryButton.text}
                                    linkPath={block.secondaryButton.path}
                                    coming={block.coming}
                                    key={i}
                                />
                            ))}

                        </BlockContent>
                    </ScrollContainer>
                </OverflowDiv>
            </ContentContainer>
        </Container>
    );
};

export default GettingBetterTimeline;

const TextContent = styled("div", {
    maxWidth: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    "@lg": {
        flexBasis: "30%",
    },
});

const Title = styled("h4", {
    ...heading150,
    marginTop: 128,
    marginBottom: 58,
    textAlign: "left",
    whiteSpace: "pre-line",
    "@md": {
        ...heading300,
        marginTop: 128,
        marginBottom: 48,
        textAlign: "center",
        whiteSpace: "unset",
    },
});

const Container = styled("div", {
    width: "100%",
    margin: "auto",
    maxWidth: 1140,
    padding: "0px 32px",
    marginTop: "-64px",
    "@md": {
        borderRadius: 16,
        width: "90%",
        margin: "0px auto",
    },
});

const ContentContainer = styled("div", {
    textAlign: "center",
    marginBottom: "0px",
    "@md": {
        marginBottom: "64px",
    },
});

const OverflowDiv = styled("div", {
    overflow: "scroll",
    marginTop: "-95px",
    "@md": {
        overflow: "initial",
        marginTop: "initial",
    },
});

const ScrollContainer = styled("div", {
    minWidth: 1024,
    scale: "0.71",
    transformOrigin: "0 50%",
    "@md": {
        minWidth: "initial",
        scale: "initial",
    },
});
const BlockContent = styled("div", {
    display: "flex",
    flexDirection: "row",
    background: "linear-gradient(#EFEFEF, #EFEFEF)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 7px",
    backgroundPosition: "0 97px",
    "@md": {
        flexDirection: "row",
    }
})

const BlockWrapper = styled("div", {

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2px 2%",
    marginBottom: 16,
    textAlign: "center",
    maxWidth: 600,
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "$transparent",
    "@lg": {
        padding: "32px 1%",
        flexBasis: "25%",
        backgroundColor: "$transparent",
    },
});
const BlockTitle = styled("h6", {
    ...heading200,
    fontSize: "24px",
    "@md": {
        margin: "58px 0 12px 0",
        fontSize: "24px",
    },
    "@media (min-width: 800px) and (max-width: 1233px)": {
        minHeight: "79px",
    },
});

const BlockComing = styled("h6", {
    ...heading200,
    margin: "5px 0 12px 0",
    fontSize: "20px",
});
const BlockLast = styled("div", {
    marginTop: "auto",
});
const Description = styled("div", {
    ...paragraphTextSmall,
    marginTop: 20,
    marginBottom: 32,
    maxWidth: 430,
    marginLeft: "auto",
    marginRight: "auto"
});
const BlockDescription = styled("p", {
    ...paragraphTextSmall,
    margin: 0,
    marginBottom: 36,
    '&.Surface': {

    },
    "@media (min-width: 800px) and (max-width: 1233px)": {
        minHeight: "107px",
    },
    "@media (min-width: 10px) and (max-width: 800px)": {
        '&.Surface': {
            marginBottom: "34px",
        },
        '&.Automated': {
            marginTop: "-34px",
        },
    },
});

const DesktopContainer = styled("div", {
    display: "none",
    "@md": {
        display: "block",
    },
})

const MobileContainer = styled("div", {
    display: "block",
    "@md": {
        display: "none",
    },
})
