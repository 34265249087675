import React from "react";
import {
    heading100,
    heading150,
    heading200,
    heading50,
    paragraphTextLarge,
    paragraphTextMedium
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";
import Button from "../shared/Button/Button";
import { Link } from "gatsby";
import CheckIcon from "../../images/checkNoBackground.inline.svg";
import CrossIcon from "../../images/cross.inline.svg";
import { Autoplay } from "swiper";

const ActionableData = () => {
    const title = "Actionable data, fast";
    const text = "Our PLX-Benchtop testing technology combines\nthe best attributes of hardness testing and\ntensile testing into a single method.";
    const pIPvsHardness = "PIP vs Hardness";
    const pIPvsTensile = "PIP vs Tensile";
    const tensileTesting = "Tensile Testing";
    const hardnessTesting = "Hardness Testing";
    const pIPTesting = "PIP Testing";

    const tableData = [
        { name: "Rapid testing", tensileTesting: false, hardnessTesting: true, pIPTesting: true },
        { name: "Stress-strain data", tensileTesting: true, hardnessTesting: false, pIPTesting: true },
        { name: "Test small/complex\ngeometries", tensileTesting: false, hardnessTesting: true, pIPTesting: true },
        { name: "Minimal sample\npreparation", tensileTesting: false, hardnessTesting: false, pIPTesting: true },
    ]

    return (
        <Wrapper>
            <Container>
                <Col45>
                    <TextWrapper >
                        <Title style={{ whiteSpace: "nowrap" }}>{title}</Title>
                        <Description>{text}</Description>
                        <StyledLink>
                            <Link to="/pip-vs-hardness">
                                <Button size="medium" tier="secondary" arrow >
                                    {pIPvsHardness}
                                </Button>
                            </Link>
                        </StyledLink>
                        <MobileLineBreak />
                        <StyledLink>
                            <Link to="/pip-vs-tensile">
                                <Button size="medium" tier="secondary" arrow>
                                    {pIPvsTensile}
                                </Button>
                            </Link>
                        </StyledLink>
                    </TextWrapper>
                </Col45>
                <Col55>
                    <Table
                        style={{ gridTemplateRows: `repeat(${tableData.length + 2}, auto)` }}
                    >
                        <FeatureName className="header" style={{ backgroundColor: "white" }}></FeatureName>
                        <FieldLeft className="header" style={{ backgroundColor: "white" }}>{tensileTesting}</FieldLeft>
                        <FieldCenter className="header" style={{ backgroundColor: "white" }}>{hardnessTesting}</FieldCenter>
                        <FieldRight className="header" style={{ whiteSpace: "nowrap",    color:"#0077ff", }} >{pIPTesting}</FieldRight>

                        {tableData.map((feature, index) => (
                            <FeatureWrapper key={index} style={{ gridRow: index + 2 }}>
                                <FeatureName>{feature.name}</FeatureName>
                                <FieldLeft>
                                    <IconWrapper>
                                        {feature.tensileTesting ? <CheckIcon /> : <StyledCrossIcon ><CrossIcon/></StyledCrossIcon>}
                                    </IconWrapper>
                                </FieldLeft>
                                <FieldCenter>
                                    <IconWrapper>
                                        {feature.hardnessTesting ? <CheckIcon /> : <StyledCrossIcon ><CrossIcon/></StyledCrossIcon>}
                                    </IconWrapper>
                                </FieldCenter>
                                <FieldRight>
                                    <IconWrapper>
                                        {feature.pIPTesting ? <CheckIcon /> : <StyledCrossIcon ><CrossIcon/></StyledCrossIcon>}
                                    </IconWrapper>
                                </FieldRight>
                            </FeatureWrapper>
                        ))}
                        <OurWrapper />
                    </Table>
                </Col55>
            </Container>
        </Wrapper>
    );
};

export default ActionableData;

const Wrapper = styled("section", {
    padding: 16,
    "@md": {
        padding: 32,
    },
    "@lg": {
        padding: 64,
    },
});

const Container = styled("div", {
    // maxWidth: 320, 
    margin: "auto",
    paddingBottom: 0,
    paddingTop: 0,
    textAlign: "center",
    "@md": {
        display: "flex",
        maxWidth: 1100,
        paddingTop: 32,
        paddingBottom: 32,
        textAlign: "left",
        justifyContent: "space-between",
        alignItems: "center",
    },
});

const TextWrapper = styled("div", {
    ...heading50,
    padding: 0,
    paddingTop: 0,
    maxWidth: '100%',
    margin: "0 auto",
    "@sm": {
        ...heading100,
        paddingTop: 8,
        paddingBottom: 8,

    },
    "@md": {
        ...heading150,
        padding: 0,
        marginRight: 0,
        maxWidth: 550,
        letterSpacing: -0.577,
    },
});

const Title = styled("h4", {
    ...heading150,
    marginBottom: 24,
    "@md": {
        ...heading200,
        letterSpacing: -0.76,
        marginTop: 0,
        fontSize: 32
    },
    "@lg": {
        fontSize: 35
    },
});

const Table = styled("div", {
    //width: "100%",
    margin: "30px auto",
    display: "grid",
    rowGap: "0px",
    width: "fit-content",
    gridTemplateColumns: "90px 65px 65px 65px",
    border: "1px solid rgba(26, 26, 26, 0.1)",
    borderRadius: "5px",
    padding: "3px 6px",
    backgroundColor: "white",
    transform: "scale(1)",
    transformOrigin: "0 50%",
    /*    transform: "scale(0.63)",
         */
    "@sm": {
        maxWidth: "420px",
        gridTemplateColumns: "auto 90px 90px 90px",
        margin: "55px auto 55px auto",

    },
    "@md": {
        rowGap: "0px",
        margin: "0 auto",
        maxWidth: "460px",
        gridTemplateColumns: "auto 95px 95px 95px",
        transform: "scale(0.85)",
    },
    "@lg": {
        gridTemplateColumns: "auto 120px 120px 120px",
        maxWidth: "550px",
        transform: "scale(0.85)",
    },
    "@l": {

        maxWidth: "550px",
        transform: "scale(0.9)",
    },
    ".header": {
        fontSize: "9px",
        "@sm": {
            fontSize: "15px",
        },
        "@md": {
            fontSize: "15px",
        },
        "@lg": {
            fontSize: "18px",
        },
    }
});

const FeatureWrapper = styled("div", {
    backgroundColor: "white",
    gridColumn: "1/5",
    display: "grid",
    gridTemplateColumns: "90px 65px 65px 65px",
    marginLeft: -2,
    marginRight: -2,
    ">div": { borderTop: "1px solid  rgba(26, 26, 26, 0.1)" },
    "@sm": {
        gridTemplateColumns: "auto 90px 90px 90px",
    },
    "@md": {
        gridTemplateColumns: "auto 95px 95px 95px",
    },
    "@lg": {
        gridTemplateColumns: "auto 120px 120px 120px",
    },
});

const FeatureName = styled("div", {
    ...paragraphTextLarge,
    fontSize: "9px",
    lineHeight: "12px",
    minHeight: '30px',
    padding: '8px 0px 8px 8px',
    whiteSpace: "pre-line",
    textAlign: "left",
    "@xs": {
        lineHeight: "12px",
      },
    "@sm": {
        padding: 10,
        lineHeight: "16px",
        fontSize: 14,
    },
    "@md": {
        padding: '20px 0px 20px 20px',
        lineHeight: "18px",
        fontSize: 15,
    },
    margin: 0,
    display: "flex",
    alignItems: "center",
});

const baseField = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center" as const,
};

const fieldHeader = {
    gridRow: 1,
    padding: 8,
    paddingTop: 4,
    color:"#5A7184",
    "@md": {
        paddingTop: 16,
        paddingBottom: 16,
    },
};

const fieldFooter = {
    gridRowEnd: -1,
    paddingTop: 0,
    paddingBottom: 6,
    "@sm": {
        paddingTop: 6,
        paddingBottom: 16,
    },
    "@md": {
        paddingTop: 12,
        paddingBottom: 24,
    },
    "& svg": {
        width: "70%",
    },
};

const FieldLeft = styled("div", {
    ...baseField,
    gridColumn: 2,
    width: "65px",
    "&.header": {
        ...fieldHeader,
    },
    "&.footer": {
        ...fieldFooter,
    },
    "@sm": { width: "90px", },
    "@md": { width: "95px", },
    "@lg": { width: "120px", }
});

const FieldCenter = styled("div", {
    ...baseField,
    gridColumn: 3,
    width: "65px",
    "&.header": {
        ...fieldHeader,
    },
    "&.footer": {
        ...fieldFooter,
    },
    "@sm": { width: "90px", },
    "@md": { width: "95px", },
    "@lg": { width: "120px", }
});

const FieldRight = styled("div", {
    ...baseField,
    fontWeight: 600,
    color: "#0077FF",
    gridColumn: 4,
    width: "65px",
    borderTopWidth: "0px!important",
    zIndex: 10,
    "&.header": {
        ...fieldHeader,
    },
    "&.footer": {
        ...fieldFooter,
    },
    "@sm": { width: "90px", },
    "@md": { width: "95px", },
    "@lg": { width: "120px", }
});

const IconWrapper = styled("div", {
    svg:  {
        marginTop: 4,
        maxWidth: "26px",
        maxHeight: "26px",
        width: 14,
        height: 14,
        "@sm": {
            width: 20,
            height: 20,
        },
        "@md": {
            width: 30,
            height: 30,
        },
        "@lg": {
            width: 40,
            height: 40,
        },
    }
});
const StyledCrossIcon = styled("div", {
    "& svg": {
    }

})


const OurWrapper = styled("div", {
    gridColumn: "4",
    gridRow: "1/-1",
    backgroundColor: "white",
    border: "1px solid rgba(26, 26, 26, 0.1)",
    borderRadius: 8,
    height: "calc(100% + 20px)",
    marginTop: "-10px"
});

const Description = styled("div", {
    ...paragraphTextMedium,
    whiteSpace: "normal",
    maxWidth: 350,
    color: "#5A7184",
    fontSize: 15,
    margin: "0px auto 15px auto",
    "@md": { whiteSpace: "pre-line", fontSize: 14, margin: 0 },
    //"@lg":{whiteSpace: "pre-line",  fontSize: 18,},
    "@lg": {
        fontSize: 16
    },

});

const StyledLink = styled("div", {
    display: "inline-block",

    "@md": {
        display: "block",
    },
    "button": {
        padding: "15px 0px 0px 0px"
    }

});


const MobileLineBreak = styled("div", {
    display: "block",
    lineHeight: "0px",
    "@md": {
        display: "none",
    },
})

const Col45 = styled("div", {
    flex: "0 0 45%",
})
const Col55 = styled("div", {
    flex: "0 0 55%",
})
